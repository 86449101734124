import pubJson from '../data/pubs.json'
import gigJson from '../data/gigs.json'
import seasonalTrailJson from '../data/seasonalTrails.json'
const thursday = new Date(2022, 9, 27)
const friday = new Date(2022, 9, 28)
const saturday = new Date(2022, 9, 29)
const sunday = new Date(2022, 9, 30)
const monday = new Date(2022, 9, 31)

function applyFilter (filter, gigs) {
    const filteredGigs = []
    for (const gig of gigs) {
        if (filter.key === 'day') {
            const date = compareDate(gig.date)
            switch (filter.value) {
                case 0:
                    if (date.getTime() === thursday.getTime()) {
                        filteredGigs.push(gig)
                    }
                    break
                case 1:
                    if (date.getTime() === friday.getTime()) {
                        filteredGigs.push(gig)
                    }
                    break
                case 2:
                    if (date.getTime() === saturday.getTime()) {
                        filteredGigs.push(gig)
                    }
                    break
                case 3:
                    if (date.getTime() === sunday.getTime()) {
                        filteredGigs.push(gig)
                    }
                    break
                case 4:
                    if (date.getTime() === monday.getTime()) {
                        filteredGigs.push(gig)
                    }
                    break
            }
        } else if (filter.key === 'dj') {
            if (!filter.value === gig.dj) {
                filteredGigs.push(gig)
            }
        } else if (filter.key === 'time') {
            switch (filter.value) {
                case 0:
                    if (checkNowTime(gig.time)) {
                        filteredGigs.push(gig)
                    }
                    break
                case 1:
                    if (checkNextTime(gig.time)) {
                        filteredGigs.push(gig)
                    }
                    break
                case 2:
                    if (checkSpecificTime(gig.time, filter.value)) {
                        filteredGigs.push(gig)
                    }
            }
        } else if (filter.key === 'selectedVenue') {
            if (gig.pub === filter.value.id) {
                filteredGigs.push(gig)
            }
        }
    }
    return filteredGigs
}

function checkNowTime (time) {
    var d = new Date() // current time
    var hours = d.getHours()
    var mins = d.getMinutes()
    var timeParts = time.split(':')

    if (hours === parseInt(timeParts[0])) {
        if (mins - timeParts[1] < 30 && mins - timeParts[1] > -30) {
            return true
        }
    } else if (hours - timeParts[0] === 1) {
        return (60 - parseInt(timeParts[1])) + mins < 30
    } else if (hours - timeParts[0] === -1) {
        return (60 + parseInt(timeParts[1])) - mins < 30
    }
    return false
}

function checkNextTime (time) {
    var d = new Date() // current time
    var hours = d.getHours()
    var mins = d.getMinutes()
    var timeParts = time.split(':')

    if (hours === parseInt(timeParts[0])) {
        if (mins < timeParts[1]) {
            return true
        }
    } else if (hours - timeParts[0] === -1) {
        return (60 + parseInt(timeParts[1])) - mins < 60
    }
    return false
}

function checkSpecificTime (time) {
    // const timeParts = time.split(':')
    // const filterParts = state.selectedTime.split(':')

    if (time === state.selectedTime) {
        return true
    }
    // if (timeParts[0] === filterParts[0] && timeParts[1] === filterParts[1]) {
    //     return true
    // }
    return false
}

function compareDate (str1) {
    var dt1 = parseInt(str1.substring(0, 2))
    var mon1 = parseInt(str1.substring(3, 5))
    var yr1 = parseInt(str1.substring(6, 10))
    var date1 = new Date(yr1, mon1 - 1, dt1)
    return date1
}

function getTimeAsNumberOfMinutes (time) {
    var timeParts = time.split(':')

    var timeInMinutes = (timeParts[0] * 60) + timeParts[1]

    return timeInMinutes
}

const getGigsDefaultState = () => {
    return {
        gigs: gigJson,
        pubs: pubJson,
        seasonalTrails: seasonalTrailJson,
        filteredGigs: [],
        selectedTime: null,
    }
}
const state = getGigsDefaultState()

const getters = {
    getGigList: state => state.gigs,
    getFilteredGigs: state => state.filteredGigs,
    getSeasonalTrails: state => state.seasonalTrails.sort(function (a, b) {
        return (a.id < b.id) ? -1 : (a.id > b.id) ? 1 : 0
    }),
    getSeasonalItemsFromSelectedTrail: (state, rootState) => {
        return rootState.getSelectedTrail == null ? [] : state.gigs.filter(gig => rootState.getSelectedTrail.items.indexOf(gig.id) > -1).sort(function (a, b) {
            return (a.time < b.time) ? -1 : (a.time > b.time) ? 1 : 0
        })
    },
    getVenueList: state => {
       const pubs = state.gigs.map(gig => gig.pub)
       return state.pubs.filter(pub => pubs.includes(pub.id))
    },
    getGigTimes: state => state.gigs.map(gig => gig.time).sort(),
    getGigActs: state => {
        const allActs = state.gigs.map(gig => gig.act).sort()
        return [...new Set(allActs)]
    },
}

const actions = {
    filterGigs ({ commit, dispatch }, filters) {
        commit('updateFilters', filters)
        if (filters == null) {
            commit('updateFilteredGigs', [])
        } else {
            let filteredGigs = [...state.gigs]
            const activeFilters = []
            for (const [key, value] of Object.entries(filters)) {
                if (key === 'day' && value !== 5) {
                    activeFilters.push({ key: key, value: value })
                } else if (key === 'time' && value !== 3) {
                    if (value === 2) {
                        commit('updateSelectedTime', filters.selectedTime)
                    } else {
                        commit('updateSelectedTime', null)
                    }
                    activeFilters.push({ key: key, value: value })
                } else if (key === 'dj' && value !== 2) {
                    activeFilters.push({ key: key, value: value })
                } else if (key === 'selectedVenue' && value !== null) {
                    activeFilters.push({ key: key, value: value })
                }
            }
            if (activeFilters.length > 0) {
                for (const filter of activeFilters) {
                    filteredGigs = applyFilter(filter, filteredGigs)
                }
            } else {
                filteredGigs = state.gigs
            }
            commit('updateFilteredGigs', filteredGigs)
        }
    },
}

const mutations = {
    updateFilteredGigs (state, gigs) {
        gigs.sort(
            function (a, b) {
                if (a.date === b.date) {
                    const timeInMinutesForA = getTimeAsNumberOfMinutes(a.time)
                    const timeInMinutesForB = getTimeAsNumberOfMinutes(b.time)
                    return timeInMinutesForA - timeInMinutesForB
                }
                return a.date > b.date ? 1 : -1
            },
        )
        state.filteredGigs = gigs
    },
    updateSelectedTime (state, time) {
        state.selectedTime = time
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
